<template>
  <div class="container">
    <div class="content1" ref="content1">
      <div >
        <p class="title">
          资料云
        </p>
        <p class="sub-title">
          <span>为企业数字化提供全方位服务</span>
        </p>
      </div>
      <div class="btns">
        <p style="text-align:left;width:70%;margin:0 auto;margin-bottom:-44px">
          <span v-html="svgAll.menu" @click="menuVisible = true"></span>
        </p>
<!--        <el-button class="red_btn" @click="toYun">免费试用</el-button>-->
        <el-button class="red_btn" @click="tolocation">免费试用</el-button>
        <el-button class="primary_btn"  @click="tolocation">合作咨询</el-button>
      </div>
      <div class="footer">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="grid-content">
              <p>200 <span style="color: #006EFF;">+</span> </p>
              <p>商业合作<br>涵盖上市/外资等企业</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p>1000 <span style="color: #DF0628;">+</span> </p>
              <p>项目经验 <br>多行业解决方案</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p>10 <span style="color: #FFDC60;">+</span> </p>
              <p>资质证书 <br>信息安全认证等</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p>2006 </p>
              <p>成立时间 <br>悠久的商业历史</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 双云科技是什么 -->
    <div class="content2">
      <div class="left">
        <img src="@/assets/images/Support/shuangyun.png" alt="">
      </div>
      <div class="right">
        <p class="p1"><span style="color: #DF0628;">双云科技</span>是什么</p>
        <p class="p2">苏州双云信息科技有限公司（双云科技）是一家创新型科技公司，专注于向客户提供先进的信息技术解决方案和个性化数字产品。 双云科技由一支资深工程师研发团队组成，具备多年信息化项目的实践案例。我们将以帮助企业创造云上价值，实现数字化变革为使命，为企业提供高质量的IoT、数字孪生、SaaS等信息化产品和服务。 通过我们的专业知识和技术实力，双云科技旨在成为企业数字化变革之旅中的可靠伙伴，共同探索和实现数字化时代的无限可能。</p>
      </div>
    </div>
    <hr ref="content3_tit" style="border: 1px solid #DCDCDC;margin:80px 0">
    <!-- 全流程资料电子化发行平台 -->
    <div class="content3">
      <div >
        <p class="title" >
          全流程资料电子化发行平台
        </p>
        <p class="sub-title">
          <span>丰富的行业解决方案</span>
        </p>
      </div>
      <div class="content3_main">
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="item">
              <div class="text">
                <p class="p1">装备制造业 </p>
                <p class="p2">随机资料发行</p>
              </div>
              <div class="pictures">
                <img style="width: 40px;" src="@/assets/images/Support/icon/Button.svg" alt="">
                <img style="width:90px;" src="@/assets/images/Support/icon/setting.png" alt="">
              </div>
            </div>
          </el-col >
          <el-col :span="6">
            <div class="item">
              <div class="text">
                <p class="p1">消费品制造业  </p>
                <p class="p2">电子说明书</p>
              </div>
              <div class="pictures">
                <img style="width: 40px;" src="@/assets/images/Support/icon/Button.svg" alt="">
                <img style="width:90px;" src="@/assets/images/Support/icon/floder.png" alt="">
              </div>
            </div>
          </el-col >
          <el-col :span="6">
            <div class="item">
              <div class="text">
                <p class="p1">医药行业  </p>
                <p class="p2">药品说明适老化发行</p>
              </div>
              <div class="pictures">
                <img style="width: 40px;" src="@/assets/images/Support/icon/Button.svg" alt="">
                <img style="width:90px;" src="@/assets/images/Support/icon/medicine.png" alt="">
              </div>
            </div>
          </el-col >
          <el-col :span="6">
            <div class="item">
              <div class="text">
                <p class="p1">建筑行业  </p>
                <p class="p2">项目资料管理发行</p>
              </div>
              <div class="pictures">
                <img style="width: 40px;" src="@/assets/images/Support/icon/Button.svg" alt="">
                <img style="width:90px;" src="@/assets/images/Support/icon/bulid.png" alt="">
              </div>
            </div>
          </el-col >

        </el-row>
      </div>
    </div>
    <!-- 双云发展历程 -->
    <div class="content4">
      <div class="title">双云科技发展历程</div>
      <!-- swiper -->
      <div class="swiper-container1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <p class="p1">2006年</p>
            <p class="p2">总公司托特科技成立</p>
            <p class="p3">
              托特科技(THOTH)一直致力于大型商业体信息化研究，研发了拥有自主知识产权的《专业市场综合管理系统》，《商业地产招商系统》，并提出了独创的专业市场电子商务解决方案。
            </p>
          </div>
          <div class="swiper-slide">
            <p class="p1">2020年</p>
            <p class="p2">双云科技作为数字化产品孵化公司注册成立</p>
            <p class="p3">专注于将创新理念与技术融合，为初创企业及项目提供全方位的支持与孵化服务，加速数字化产品的市场落地与成长。双云科技凭借其敏锐的市场洞察力、专业的技术团队以及丰富的行业资源，致力于成为推动数字化转型与创新的领军力量。</p>
          </div>
          <div class="swiper-slide">
            <p class="p1">2021年</p>
            <p class="p2">面向装备制造业“随机资料云”研发启动</p>
            <p class="p3">由一支资深工程师研发团队组成，具备多年信息化项目的实践案例。以帮助企业创造云上价值，实现数字化变革为使命，为企业提供高质量的IoT、数字孪生、SaaS等信息化产品和服务。</p>
          </div>
          <div class="swiper-slide">
            <p class="p1">2022年</p>
            <p class="p2">升级为通用产品“资料云”</p>
            <p class="p3">“资料云”是一款面向企业的SaaS（Software-as-a-Service，软件即服务）产品，专注于商品电子说明书的创建、管理和发行，同时覆盖更广泛的资料管理需求。它通过云端服务，实现了企业资料的统一存储、管理、协作和分享，极大地提高了企业资料管理的效率和安全性。</p>
          </div>
          <div class="swiper-slide">
            <p class="p1">2023年</p>
            <p class="p2">获得民营科技企业科技型中小企业认证</p>
            <p class="p3">在日益数字化的今天，信息安全与技术创新已成为企业发展的两大核心驱动力。我司荣幸地宣布，我们不仅成功通过了ISO27001信息安全管理体系的国际认证，还荣获了民营科技企业科技型中小企业的官方认证，这两项殊荣标志着我司在信息安全保障与科技创新能力上迈上了新的台阶。</p>
          </div>
          <div class="swiper-slide">
            <p class="p1">2024年</p>
            <p class="p2">药品电子说明书推出（国家适老化改造）</p>
            <p class="p3">随着我国老龄化社会的加速发展，老年人在用药过程中面临的阅读药品说明书困难问题日益凸显。为应对这一挑战，国家药品监督管理局（以下简称“国家药监局”）积极推动药品说明书的适老化改造，其中，药品电子说明书的推出是这一改革的重要举措。</p>
          </div>
        </div>
        <div class="swiper-pagination1"></div>
      </div>
    </div>
    <!-- 公司资质证书 -->
    <div class="content5">
      <div class="title">公司资质证书</div>

        <!-- Swiper -->
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="item in swiperList" :key="item.id" class="swiper-slide"
                    :style="`background-image:url(${item.imgUrl})`">
                </div>
            </div>
            <div class="swiper-pagination2"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div>
    </div>

    <!-- 与公司合作的客户 -->
    <div class="content6" ref="content6">
      <div class="title">与公司合作的客户</div>
      <div class="content5_main">
        <img src="@/assets/images/Support/company/All.png" alt="">
      </div>
    </div>

    <!-- 菜单 -->
    <el-dialog
      title=""
      :visible.sync="menuVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="70%"
      >
      <div class="menu">
        <div class="left">
          <div class="left-top">
            <div class="logo">
              <img src="@/assets/images/logo/uoon_logo.png" width="40px">
              <div >
                <p style="font-size: 18px;letter-spacing:2px">双云科技</p>
                <p style="font-size: 12px;">助力数字化成功</p>
              </div>
            </div>
            <div style="font-size: 22px;font-weight: 600;line-height: 1.5">
              <p>提升您的资料管理效率，<br />驱动业务数字化。</p>
            </div>
            <div class="address">
              <p>公司地址：</p>
              <hr style="border:1px solid #ECECEC;margin:10px 0;">
              <p>苏州双云信息科技有限公司</p>
              <p>江苏省苏州市相城区嘉元路959号元和大厦</p>
            </div>
          </div>

          <div class="bottom">
            <p style="margin-bottom: 20px;"> <span style="font-size: 20px;color:#fff;">联系我们 </span>
            咨询或合作</p>

            <p>深耕二十余年，积累了丰富的行业经验和专业知识。无论您是寻求提升业务效率的新方法，还是需要解决复杂的行业挑战，我们都将为您提供全面的支持和专业的指导。</p>
          </div>

        </div>

        <div class="center">
          <p>双云科技</p>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>
              <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px;width:12px;">解决方案
            </p>
            <ul class="itemUl">
              <li @click="toPages('a')">药品说明书适老化改造</li>
              <li>装备制造业解决方案</li>
              <li @click="toPage3('c')">电子说明书解决方案</li>
              <li>建筑行业解决方案</li>
            </ul>
          </div>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>合作与支持</p>
            <ul class="itemUl">
              <li @click="toPages2('a')">公司简介</li>
              <li @click="toPages2('b')">联系我们</li>
              <li @click="toPages2('c')">合作客户</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <span >关闭</span>
          <img src="@/assets/images/icon/close.png" @click="closeMenu">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import svgAll from '@/assets/images/svg/svg.js'
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
  export default {
    data() {
      return {
        svgAll,
        menuVisible:false,
        swiper1: null,  //发展历程
        swiper2: null,  //证书
        swiperList: [
          {
            id: 1,
            imgUrl: require("@/assets/images/Support/certificate/certificate1.png"),
          },
          {
            id: 2,
            imgUrl: require("@/assets/images/Support/certificate/certificate2.png"),
          },
          {
            id: 3,
            imgUrl: require("@/assets/images/Support/certificate/certificate3.png"),
          },
          {
            id: 4,
            imgUrl: require("@/assets/images/Support/certificate/certificate4.png"),
          },
          {
            id: 5,
            imgUrl: require("@/assets/images/Support/certificate/certificate5.png"),
          },
        ],
      };
    },
    mounted() {
        this.getSwiper()
    },

    methods:{
      toYun() {
        window.open('https://yun.uoon.cn/registrationPage', '_blank');
      },
      closeMenu(){
        this.menuVisible = false
      },
      tolocation() {
        this.$emit('tolocation' ,'');
      },
      toTop(type) {
        this.$nextTick(() => {
          // 丰富的行业解决方案
          if(type == 'a') {
            if (this.$refs.content3_tit) {
              window.scrollTo({
                top: this.$refs.content3_tit.offsetTop,
                behavior: 'smooth',
              });
            }
          }
          // 关于我们
          if (type == 'b') {
            if (this.$refs.content1) {
              window.scrollTo({
                top: this.$refs.content1.offsetTop,
                behavior: 'smooth',
              });
            }
          }
          // 合作客户
          if (type == 'c') {
            if (this.$refs.content6) {
              window.scrollTo({
                top: this.$refs.content6.offsetTop,
                behavior: 'smooth',
              });
            }
          }

        });
      },
      getSwiper() {
        this.swiper1 = new Swiper(".swiper-container1", {
          loop: true, // 无缝
          autoplay: { //自动开始
            delay: 3000, //时间间隔
            disableOnInteraction: false, //*手动操作轮播图后不会暂停*
          },
          slidesPerView: 4,
          // spaceBetween: 30,
          centeredSlides: true,  //两头的item显示一半
          // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
          observer: true,
          observeParents: true,
        })
        this.swiper2 = new Swiper(".swiper-container", {
            loop: true, // 无缝
            autoplay: { //自动开始
                delay: 3000, //时间间隔
                disableOnInteraction: false, //*手动操作轮播图后不会暂停*
            },
            paginationClickable: true,
            slidesPerView: 5, // 一组5个
            // spaceBetween: 30, // 间隔
            // 如果需要前进后退按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
            observer: true,
            observeParents: true,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination2',
                clickable: true, // 分页器可以点击
            },
        })
      },
      toPages(type) {
        this.menuVisible = false;
        if(type == 'a') {
          this.toTop();
        }
        if (type == 'b' || type == 'c' || type == 'd') {
          this.toTop('a')
        }
      },
      toPages2(type) {
        this.menuVisible = false;
        this.$emit('toPages2', type);
      },
    },
  }
</script>

<style lang="scss" scoped>
.container{
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding-bottom: 100px;
  .title{
    color: #DF0628;
    font-size: 46px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 4px;
  }
  .sub-title{
    margin-top: 20px;
    font-size: 34px;
    color: #38505E;
    font-weight: 600;
  }
}
.content1{
  .title,.sub-title{
    font-size: 40px;
  }
  .btns{
    margin:30px 0 80px;
  }
  .footer{
    padding: 30px 15% ;
    // height: 100px;
    background-color: #292930;
    color: #fff;
    .grid-content{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      p{
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.1;
        margin-right: 10px;
        &:nth-child(2){
          color: #D0D0D0;
          font-size: 12px;
          font-weight: normal;
          line-height: 156.5%;
          letter-spacing: 2px;
        }
      }
    }
  }
}
.content2{
  background-color: #fff;
  padding: 80px 15% 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    width: 60%;
    margin-right: 50px;
    img{
      width: 100%;
    }
  }
  .right{
    width: 40%;
    text-align: left;
    .p1{
      color: #38505E;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .p2{
      color: #313131;
      font-family: "Alibaba PuHuiTi 2.0";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 2;
    }
  }
}
.content3{
  width: 65%;
  margin: 0 auto;
  .title{
    letter-spacing: 8px;
  }
  .content3_main{
    margin: 50px 0;
    .item{
      height: 160px;
      padding: 20px 5px 10px 20px;
      border-radius: 8px;
      background: linear-gradient(151deg, #FFF8F8 10.38%, #F8F8F8 95.44%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .text{
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 2.4px;
        color:#313131;
        .p1{
          color: #DF0628;
        }
        .p2{
          font-size: 14px;
        }

      }
      .pictures{
        display: flex;
        justify-content: space-between;
        img{
          align-self: flex-start;
          &:nth-child(2){
            align-self: flex-end;
          }
        }
      }
    }
  }
}
// 双云发展历程
.content4{
  // height: 70vh;
  background: url('@/assets/images/Support/line_bg.png') no-repeat bottom;
  background-color: #292930;
  padding-bottom: 100px;
  .title{
    color: #FFF;
    font-size: 36px;
    font-weight: 600;
    padding: 70px 0 50px;
  }

  /* swiper */
  .swiper-container1 {
    width: 100%;
    height: 320px;
    overflow: hidden;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      transition: 300ms;
      transform: scale(0.85);
      //
      text-align: left;
      box-sizing: border-box;
      padding: 30px;
      border-radius: 30px;
      border: 2px solid #FFF;
      background: #FFF;
      .p1{
        color: #DF0628;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
      }
      .p2{
        color: #DF0628;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 50px;
      }
      .p3{
        color: #313131;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 1px;
      }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
        transform: scale(1);
    }
  }
}
// 公司资质证书
.content5{
  background-color: #F6F6F6;
  .title{
    color: #38505E;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    padding: 70px 0 50px;
  }
  .swiper-container {
    overflow: hidden;
    margin: 0 auto;
    background-color: #F6F6F6;
    .swiper-slide {
        position: relative;
        height: 328px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 40px;
      }
      .swiper-pagination2{
        margin-bottom: 20px;
      }
    }
}
/* 与公司合作的客户 */
.content6{
  width: 70%;
  margin: 0 auto;
  padding: 80px 0;
  .title{
    margin-bottom: 50px;
    color: #38505E;
    font-size: 36px;
    font-weight: 600;
    line-height: 110.5%;
  }
  .content5_main{
    img{
      width: 100%;
    }
  }
}

.itemUl li{
  cursor: pointer;
}
.itemUl li:hover{
  text-decoration: underline;
}

/* 菜单弹窗 */
::v-deep .el-dialog__header{
  padding: 0 ;
}
::v-deep .el-dialog__body{
  padding: 0 ;
}
</style>
