import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index'
Vue.use(VueRouter)

const routes = [
   {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: '首页',
    component: Index
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
