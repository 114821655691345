<template>
  <div class="container">
    <div class="first-page">
      <div class="header">
        <div class="logo" @click="activeIndex = '0'">
          <img src="@/assets/images/logo/uoon_logo.png" alt="">
        </div>
        <el-menu
          :default-active="activeIndex"
          text-color="#000"
          active-text-color="#DF0628"
          style="border:none;"
          class="navmenu"
          mode="horizontal"
          @select="skipTo"
        >
          <el-menu-item index="0" >首页</el-menu-item>
          <el-menu-item index="1" >解决方案</el-menu-item>
          <el-menu-item index="2" >合作与支持</el-menu-item>
        </el-menu>
        <el-button class="red_btn" @click="tolocation">合作联系  <i class="el-icon-top-right"></i></el-button>
      </div>

      <Home ref="Home" @toPages="toPages" @toPages2="toPages2" @tolocation="tolocation" v-show="activeIndex == '0'"></Home>
      <Solution ref="Solution" @toPages="toPages" @toPages2="toPages2" @tolocation="tolocation" v-show="activeIndex == '1'"></Solution>
      <Support ref="Support" @toPages="toPages" @toPages2="toPages2" @tolocation="tolocation" v-show="activeIndex == '2'"></Support>

      <!-- 表单 -->
      <div class="content_form" ref="consult" id='consult' :style="{'background-color':activeIndex == '1' ? '#fff' : '#f6f6f6'}">
        <div v-if="activeIndex != '1'" style="width: 70%;margin: 0 auto;padding:60px 0; border-bottom: 2px solid #fff;"  >
          <p class="title">
            与我们联系
          </p>
          <p class="sub-title">
            <span>咨询合作</span>
          </p>
        </div>
        <div v-if="activeIndex == '1'" style="width: 70%;margin: 0 auto;padding:60px 0; border-bottom: 2px solid #F6F6F6;display:flex;justify-content: space-between;align-items:end;text-align: left;" >
          <div style="line-height: 1.5;">
            <p class="title">
              与我们联系
            </p>
            <p class="sub-title" style="margin-top: 0;">
              <span>咨询合作</span>
            </p>
          </div>
          <p>
          <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px">双云科技
        </p>
        </div>
        <div class="content_form_main">
          <el-row :gutter="50">
            <el-col :span="8">
              <div :style="{backgroundColor:'#f6f6f6',padding:activeIndex == '1' ?'15px' : 0}">
                <div class="grid-content" >
                  <span >联系邮箱</span>
                  <hr style="margin: 15px 0; border: 1px solid #ececec">
                  <div class="text">
                    <img src="@/assets/images/home/contact/email.png" alt="">
                    <div>
                      <p class="p1">商务邮箱</p>
                      <p class="p2"><a style="color: #313131;text-decoration: none" href="mailto:gf@uoon.cn">gf@uoon.cn</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div :style="{backgroundColor:'#f6f6f6',padding:activeIndex == '1' ?'15px' : 0}">
                <div class="grid-content">
                  <span >联系电话</span>
                  <hr style="margin: 15px 0; border: 1px solid #ececec">
                  <div class="text">
                    <img src="@/assets/images/home/contact/phone.png" alt="">
                    <div>
                      <p class="p1">手机电话</p>
                      <p class="p2"><a style="color: #313131;text-decoration: none" href="tel:15250072737">152-5007-2737</a></p>
                      <p class="p2"><a style="color: #313131;text-decoration: none" href="tel:0512-65521899">0512-65521899</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div :style="{backgroundColor:'#f6f6f6',padding:activeIndex == '1' ?'15px' : 0}">
                <div class="grid-content">
                  <span >公司位置</span>
                  <hr style="margin: 15px 0; border: 1px solid #ececec">
                  <div class="text">
                    <img src="@/assets/images/home/contact/local.png" alt="">
                    <div>
                      <p class="p1">办公地址</p>
                      <p class="p2">江苏省苏州市相城区嘉元路959号元和大厦</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>

          </el-row>
        </div>
       <div class="form-box" :style="{'background-color':activeIndex == '1' ? '#f6f6f6' : '#fff'}">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="您的名字" prop="infoName">
                  <el-input v-model="ruleForm.infoName" placeholder="请输入您的名字"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="您的手机号" prop="infoPhone">
                  <el-input v-model="ruleForm.infoPhone"  placeholder="请输入手机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="企业名称" prop="infoFirm">
                  <el-input v-model="ruleForm.infoFirm"  placeholder="请输入公司名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="您的邮箱" prop="infoEmail">
                  <el-input v-model="ruleForm.infoEmail"  placeholder="请输入您的邮箱"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="留言" prop="infoContent">
                  <el-input type="textarea" :rows="8" v-model="ruleForm.infoContent"  placeholder="留下您想说的~"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item style="text-align: center;">
              <el-button :loading="subLoading" class="red_btn" style="width: 200px;margin-top:20px" @click="submitForm('ruleForm')">留言咨询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <footer class="footer">
        <p class="title" style="color:#fff">
          为企业数字化升级助力
        </p>
        <p class="sub-title">
          <span> 快速注册体验电子化药品说明书系统</span>
        </p>
        <div class="btns">
<!--          <el-button class="red_btn" @click="toYun">免费试用</el-button>-->
          <el-button class="red_btn" @click="toPages2('b')">免费试用</el-button>
          <el-button class="primary_btn" @click="toPages2('b')">合作咨询</el-button>
        </div>
        <hr style="border-color:#555;margin:50px 0">

        <div class="footer-content">
          <el-row>
            <el-col :span="13">
              <div style="display: flex;">
                <img style="height: 40px;margin-right: 10px" src="@/assets/images/logo/uoon_logo.png" alt="">
                <p>双云科技 <br>数字化服务商</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="foot_content_right">
                <p class="title">解决方案</p>
                <p @click="toPages('a')">药品说明书适老化改造</p>
                <p >装备制造业解决方案</p>
                <p @click="toPages('c')">电子说明书解决方案</p>
                <p>建筑行业解决方案</p>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="foot_content_right">
                <p class="title">合作与支持</p>
                <p @click="toPages2('a')">公司简介</p>
                <p @click="toPages2('b')">联系我们</p>
                <p @click="toPages2('c')">合作客户</p>
              </div>
            </el-col>
          </el-row>
        </div>
        <hr style="border-color:#555;margin-top:50px">

        <p class="copyright">Copyright © 2024 苏州双云信息科技有限公司 . All Rights Reserved. 苏 <a href="https://beian.miit.gov.cn/#/Integrated/index">ICP备15041412号-1</a></p>
      </footer>

      <div class="backTop" v-if="showBackToTop" @click="scrollToTop">
        <img src="@/assets/images/logo/backTop.png" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import Home  from './component/Home';
import Solution  from './component/Solution';
import Support  from './component/Support';
import axios from 'axios';
  export default {
    components: {
      Home,
      Solution,
      Support,
    },
    data() {
      return {
        showBackToTop: false, // 控制按钮的显示
        activeIndex: "1",  // 默认激活对应index
        ruleForm: {
          infoName: '',
          infoPhone: null,
          infoFirm: '',
          infoEmail: '',
          infoContent: '',
        },
        rules: {
          infoName: [
            { required: true, message: '请输入您的名字', trigger: 'blur' },
          ],
          infoPhone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
          ],
          // infoFirm: [
          //   { required: true, message: '请输入公司名称', trigger: 'blur' },
          // ],
          // infoEmail: [
          //   { required: true, message: '请输入您的邮箱', trigger: 'blur' },
          // ],
        },
        subLoading:false,  //提交按钮
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      tolocation() {
        window.scrollTo(0, window.pageYOffset + document.querySelector("#consult").getBoundingClientRect().top);
      },
      skipTo(index){
        this.activeIndex = String(index);
        // this.$parent.jump(index)
      },
      handleScroll() {
        // 当页面滚动超过一定距离时，显示按钮
        this.showBackToTop = window.pageYOffset > 100;
      },
      scrollToTop() {
        // 平滑滚动到页面顶部
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      toPages(type) {
        if(type == 'a') {
          this.activeIndex = '0';
          if (this.$refs.Home) {
            this.$refs.Home.toTop('a')
          }
        }
        if (type == 'c') {
          this.activeIndex = '0';
          if (this.$refs.Home) {
            this.$refs.Home.toPage3('c')
          }
        }
        if (type == 'b' || type == 'd') {
          this.activeIndex = '2';
          if (this.$refs.Support) {
            this.$refs.Support.toTop('a')
          }
        }
      },
      toPages2(type) {
        if (type == 'a') {
          this.activeIndex = '2';
          if (this.$refs.Support) {
            this.$refs.Support.toTop('b')
          }
        }
        if (type == 'b') {
          window.scrollTo({
            top: this.$refs.consult.offsetTop,
            behavior: 'smooth',
          });
        }
        if (type == 'c') {
          this.activeIndex = '2';
          if (this.$refs.Support) {
            this.$refs.Support.toTop('c')
          }
        }
      },
      toYun() {
        window.open('https://yun.uoon.cn/registrationPage', '_blank');
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.subLoading = true
            axios.post('/api/blade-system/domainmessage/save',this.ruleForm)
              .then(res => {
                this.subLoading = false
                if(res.data.code == 200){
                  this.$message({
                    message: '提交成功，稍后会有工作人员联系您~',
                    type: 'success'
                  });
                  this.$refs[formName].resetFields();
                }else {
                  this.$message.error('提交失败');
                }
              }).catch(err => {
                this.subLoading = false
                this.$message.error(err);
              })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
/* @media 分辨率1920*1080*/
//  [150%,175%]
@media  (min-device-width:1097px) and (max-device-width:1281px) {
  .content_form{
    .content_form_main{
      .grid-content{
        height: 180px !important;
        padding: 15px !important;
        .text{
          .p1{
            font-size: 18px !important;
          }
          .p2{
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
// (100%,150%]
@media  (min-device-width:1281px) and (max-device-width:1920px) {

}
@media (min-device-width: 1920px)  {
  
}
.container{
  margin: 0 auto;
  text-align: center;
  width: 100%;
  .title{
    color: #DF0628;
    font-size: 46px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 4px;
  }
  .sub-title{
    margin-top: 20px;
    font-size: 34px;
    color: #38505E;
    font-weight: 600;
  }
}
.first-page{
  width: 100%;
  height: 100vh;
  background: url('@/assets/images/home/background.png') no-repeat center center;
  background-size: 100% 100%;
}
.header{
    padding: 30px 200px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:5px;
    .logo{
      height: 40px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .el-menu{
      // width: 330px;
      height: 54px;
      box-sizing:border-box;
      border-radius: 50px;
      padding: 6px;
      ::v-deep .el-menu-item{
        text-align: center;
        height: 42px;
        line-height: 42px;
        padding: 0 30px;
        border-radius: 50px;
        border-bottom: none !important;   //去掉选中菜单的下划线
      }
      ::v-deep .el-menu-item.is-active {
        border-bottom: none !important;   //去掉选中菜单的下划线
        background-color: #F4F4F4;
        border-radius: 50px;
        font-weight: 700;
      }
    }
}

/* 表单 */
.content_form{
  margin-top: -100px;
  padding-bottom: 70px;
  border-radius: 100px 100px 0px 0px;
  // background: #F6F6F6;
  .content_form_main{
    width: 60%;
    margin: 0 auto;
    margin-top: 50px;
    .grid-content{
      height: 160px;
      background-color: #fff;
      padding: 15px 20px;
      span{
        display: block;
        width: 100px;
        height: 40px;
        line-height: 40px;
        border-radius: 59px;
        background: #F6F6F6;
      }
      .text{
        display: flex;
        text-align: left;
        img{
          height: 20px;
          margin-right: 10px;
        }
        .p1{
          color: #313131;
          font-size: 20px;
          font-weight: 500;
          line-height: 1;
          margin-bottom: 10px;
        }
        .p2{
          color: #515151;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
        }
      }
    }
  }
  .form-box{
    width: 60%;
    box-sizing: border-box;
    padding: 40px 60px;
    margin: 0 auto;
    margin-top: 40px;
    text-align: left;
    border-radius: 15px;
    background: #FFF;
    ::v-deep .el-form-item__label{
      padding: 0;
    }
  }
}
.footer{
  background-color: #292930;
  padding-top: 50px;
  color: #fff;
  .title{
    font-size: 46px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 4px;
  }
  .sub-title{
    font-size: 18px;
    // font-weight: 700;
    line-height: 100px; /* 416.667% */
  }
  .btns{
    .primary_btn{
      border: 1px solid #fff;
      color: #fff;
      background-color: #292930;
    }
  }
  .footer-content{
    padding: 0 200px ;
    text-align: left;
    .foot_content_right{
      font-size: 14px;
      color: #D0D0D0;
      line-height: 2;
      .title{
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
      }
    }
  }
  .copyright{
    color: #D0D0D0;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 60px;
    a{
      color: #D0D0D0;
      font-style: normal;
      text-decoration-line: underline;
    }
  }
}

.backTop{
  // display: none; /* 初始隐藏按钮 */
  position: fixed; /* 固定在右下角 */
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  z-index: 999; /* 确保按钮在其他元素之上 */
  img{
    border-radius: 50%;
    width: 50px;
  }
}
/* 当页面滚动时显示按钮 */
.show {
  display: block;
}
.foot_content_right p{
  cursor: pointer;
}
.foot_content_right p:hover{
  text-decoration: underline;
}
.foot_content_right .title:hover{
  text-decoration: none;
  cursor: default;
}
</style>
