<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    if (this.isMobile()) {
      window.location.href = 'http://mobile.uoon.cn';
    }
  },
  methods: {
    isMobile() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      return (width <= 768 && height <= 1024) || (width <= 1024 && height <= 768);
    }
  }
};
</script>



