<template>
  <div class="container">
    <div class="content1">
      <p class="title">
        资料云<span style="color:#38505E"> 助力企业资料数字化升级</span>
      </p>
      <p class="sub-title">
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 提高企业资料的数字化管理</span>
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 降低人为错误的风险</span>
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 增强数据的安全性和可追溯性</span>
      </p>
      <div class="btns">
<!--        <el-button class="red_btn" @click="toYun">免费试用</el-button>-->
        <el-button class="red_btn" @click='tolocation'>免费试用</el-button>
        <el-button class="primary_btn" @click='tolocation'>合作咨询</el-button>
      </div>
      <p style="text-align:left;width:70%;margin:0 auto;margin-bottom:-44px">
        <span v-html="svgAll.menu" @click="menuVisible = true"></span>
      </p>
      <img style="width:60%" src="@/assets//images//home/data.png" alt="">
      <p style="color: #38505E;font-size:20px;font-weight: 600;line-height:2">
        他们也在用 <span style="color:#DF0628">资料云</span>
      </p>
      <img style="height:60px;" src="@/assets/images/home/using_brand.png" alt="">
    </div>
    <hr style="margin-top:50px">

    <div ref="content2" class="content2">
      <div style="padding:60px 0">
        <p class="title">
          全流程资料电子化发行平台
        </p>
        <p class="sub-title">
          <span>行业解决方案</span>
        </p>
      </div>
      <div class="item">
        <div class="item_content">
          <p class="p1">
            <span class="underline-text">药品说明书适老化</span>
          </p>
          <p class="p2">配合国家药监局《药品说明书适老化改革试点工作方案》 通过对药品说明书电子化并实现适老化设计，确保老年用户能够轻松访问、阅读和理解药品说明书，从而提高药品使用的安全性和便利性。</p>
          <p>
            <img @click='tolocation' src="@/assets/images/home/get_scheme_btn.png" alt="" style="height: 60px;margin-top:40px">
          </p>
        </div>
        <div class="item_phone">
          <img src="@/assets/images/home/phone1.png" alt="">
        </div>
      </div>
      <div class="item item2">
        <div class="item_phone">
          <img src="@/assets/images/home/phone2.png" alt="">
        </div>
        <div class="item_content">
          <p class="p1">
            <span class="underline-text">装备制造业解决方案</span>
          </p>
          <p class="p2">针对装备制造业，特别是特种设备制造业，我们提供一站式解决方案，旨在高效、安全地满足复杂的文档管理需求和监管部门的合规要求。</p>
          <p>
            <img @click='tolocation'  src="@/assets/images/home/get_scheme_btn.png" alt="" style="height: 60px;margin-top:40px">
          </p>
          </div>
      </div>
      <div class="item" ref="phone3">
        <div class="item_content">
          <p class="p1">
            <span class="underline-text">电子说明书解决方案</span>
          </p>
          <p class="p2">“资料云”是一款面向企业的SaaS产品，专注于商品电子说明书的创建、管理和发行。它旨在提供一种简单、高效且互动的方式来处理产品电子说明书。</p>
          <p>
            <img @click='tolocation' src="@/assets/images/home/get_scheme_btn.png" alt="" style="height: 60px;margin-top:40px">
          </p>
        </div>
        <div class="item_phone">
          <img src="@/assets/images/home/phone3.png" alt="">
        </div>
      </div>
      <div class="item item2">
        <div class="item_phone">
          <img src="@/assets/images/home/phone4.png" alt="">
        </div>
        <div class="item_content">
          <p class="p1">
            <span class="underline-text">建筑行业解决方案</span>
          </p>
          <p class="p2">旨在为建筑行业提供一个高效、便捷的项目文档管理解决方案。通过云端服务，实现项目文档的统一存储、管理、协作和分享，从而提高项目管理效率并降低项目风险。</p>
          <p>
            <img @click='tolocation' src="@/assets/images/home/get_scheme_btn.png" alt="" style="height: 60px;margin-top:40px">
          </p>
        </div>
      </div>
    </div>

    <div class="content3">
      <div style="padding:60px 0; background-color: #ECECEC; border-bottom: 1px solid #DCDCDC;">
        <p class="title">
          资料云电子化发行平台
        </p>
        <p class="sub-title">
          <span>产品定位</span>
        </p>
      </div>
      <div class="content3_main">
          <div class="text">
            <p>全流程资料电子化发行平台在各个领域都具有丰富的应用场景，可以帮助企业提高工作效率、降低成本、增强合规性，并提升客户服务和沟通效果。</p>
            <p class="tag">
              <span>
                <img  src="@/assets/images/home/dui_black.png" alt="">
                一品一码
              </span>
              <span>
                <img src="@/assets/images/home/dui_black.png" alt="">
                高效协同
              </span>
              <span>
                <img src="@/assets/images/home/dui_black.png" alt="">
                AI知识库
              </span>
              <span>
                <img src="@/assets/images/home/dui_black.png" alt="">
                移动H5
              </span>
              <span>
                <img src="@/assets/images/home/dui_black.png" alt="">
                数据安全
              </span>
            </p>
          </div>
          <div style="width:75%;margin: 0 auto">
            <img style="width:100%;margin:50px 0" src="@/assets/images/home/content3.svg" alt="">
          </div>
      </div>
    </div>

    <div class="content4">
      <div style="padding:60px 0; ">
        <p class="title">
          资料云电子化发行平台
        </p>
        <p class="sub-title">
          <span>为企业数字化助力</span>
        </p>
      </div>
      <div class="content4_main">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Call.png" alt="">
                <span>人工服务</span>
              </p>
              <p class="p2">
                <span>7*24h</span>
              </p>
              <p class="p2">
                <span>
                  在线客服解答
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Set.png" alt="">
                <span>技术支持</span>
              </p>
              <p class="p2">
                <span>工作时段</span>
              </p>
              <p class="p2">
                <span>
                  1对1服务
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Shopping_bag.png" alt="">
                <span>售前专家</span>
              </p>
              <p class="p2">
                <span>分析企业需求</span>
              </p>
              <p class="p2">
                <span>
                  定制符合企业的解决方案
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Question.png" alt="">
                <span>咨询团队</span>
              </p>
              <p class="p2">
                <span>提供企业管理升级系统方案</span>
              </p>
              <p class="p2">
                <span>
                  咨询服务
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Help.png" alt="">
                <span>帮助中心</span>
              </p>
              <p class="p2">
                <span>AI自助答疑</span>
              </p>
              <p class="p2">
                <span>
                  操作文档及视频演示
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <p class="p1">
                <img src="@/assets/images/home/zhuli/Health.png" alt="">
                <span>售后保障</span>
              </p>
              <p class="p2">
                <span>更新升级</span>
              </p>
              <p class="p2">
                <span>
                  数据的安全性和完整性。
                </span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 菜单 -->
    <el-dialog
      title=""
      :visible.sync="menuVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="70%"
      >
      <div class="menu">
        <div class="left">
          <div class="left-top">
            <div class="logo">
              <img src="@/assets/images/logo/uoon_logo.png" width="40px">
              <div >
                <p style="font-size: 18px;letter-spacing:2px">双云科技</p>
                <p style="font-size: 12px;">助力数字化成功</p>
              </div>
            </div>
            <div style="font-size: 22px;font-weight: 600;line-height: 1.5">
              <p>提升您的资料管理效率，<br />驱动业务数字化。</p>
            </div>
            <div class="address">
              <p>公司地址：</p>
              <hr style="border:1px solid #ECECEC;margin:10px 0;">
              <p>苏州双云信息科技有限公司</p>
              <p>江苏省苏州市相城区嘉元路959号元和大厦</p>
            </div>
          </div>

          <div class="bottom">
            <p style="margin-bottom: 20px;"> <span style="font-size: 20px;color:#fff;">联系我们 </span>
            咨询或合作</p>

            <p>深耕二十余年，积累了丰富的行业经验和专业知识。无论您是寻求提升业务效率的新方法，还是需要解决复杂的行业挑战，我们都将为您提供全面的支持和专业的指导。</p>
          </div>

        </div>

        <div class="center">
          <p>双云科技</p>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>
              <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px;width:12px;">解决方案
            </p>
            <ul class="itemUl">
              <li @click="toPages('a')">药品说明书适老化改造</li>
              <li>装备制造业解决方案</li>
              <li @click="toPage3('c')">电子说明书解决方案</li>
              <li>建筑行业解决方案</li>
            </ul>
          </div>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>合作与支持</p>
            <ul class="itemUl">
              <li @click="toPages2('a')">公司简介</li>
              <li @click="toPages2('b')">联系我们</li>
              <li @click="toPages2('c')">合作客户</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <span >关闭</span>
          <img src="@/assets/images/icon/close.png" @click="closeMenu">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import svgAll from '@/assets/images/svg/svg.js'
  export default {
    data(){
      return{
        svgAll,
        menuVisible:false,
      }
    },
    methods:{
      closeMenu(){
        this.menuVisible = false
      },
      tolocation() {
        this.$emit('tolocation' ,'');
      },
      toTop() {
        this.$nextTick(() => {
          if (this.$refs.content2) {
            window.scrollTo({
              top: this.$refs.content2.offsetTop,
              behavior: 'smooth',
            });
          }
        });
      },
      toYun() {
        window.open('https://yun.uoon.cn/registrationPage', '_blank');
      },

      toPages(type) {
        this.menuVisible = false;
        if(type == 'a') {
          this.toTop();
        }
        if (type == 'b' || type == 'c' || type == 'd') {
          this.toTop('a')
        }
      },
      toPages2(type) {
        this.menuVisible = false;
        this.$emit('toPages2', type);
      },
      toPage3() {
        window.scrollTo({
          top: this.$refs.phone3.offsetTop,
          behavior: 'smooth',
        });
      },
    }
  }
</script>

<style media="screen" lang="scss" scoped>
/* @media 分辨率1920*1080*/
@media (max-device-width: 1920px) {
  .content3{
    .content3_main{
      .text{
        width: 65% !important;
      }
    }
  }
}
//  [150%,175%]
@media  (min-device-width:1097px) and (max-device-width:1281px) {
  .content4{
    .content4_main{
      .grid-content{
        padding: 20px 0 0 20px ;
        font-size: 14px ;
      }
    }
  }

}
// (100%,150%]
@media  (min-device-width:1281px) and (max-device-width:1920px) {
  .content4{
    .content4_main{
      .grid-content{
        padding: 30px 0 0 30px ;
        font-size: 16px ;
      }
    }
  }
}
@media (min-device-width: 1920px)  {
  .content4{
    .content4_main{
      .grid-content{
        padding: 50px 0 0 50px;
        font-size: 16px ;
      }
    }
  }
}

</style>
<style lang="scss" scoped>

.container{
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: center;
  width: 100%;
  .title{
    color: #DF0628;
    font-size: 46px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 4px;
  }
  .sub-title{
    margin-top: 20px;
    font-size: 34px;
    color: #38505E;
    font-weight: 600;
  }
}
.content1{
  width: 100%;
  height: 100%;

  .sub-title{
    color: #38505E;
    font-size: 14px;
    line-height: 2;
    font-weight: normal;
    span{
      margin: 10px;
    }
  }
  .btns{
    margin:30px
  }
}
.content2{
  .item{
    margin-bottom: 60px;
    height: 80vh;
    display: flex;
    .item_content{
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        width: 50%;
        margin-left: 35%;
        text-align: left;
        letter-spacing: 2px;
      }
      .p1{
        color: #38505E;
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 30px;
        .underline-text {
          display: inline-block;
          border-bottom: 2px solid #38505E;
          padding-bottom: 5px;
        }
      }
      .p2{
        color: #333333;
        font-size: 16px;
        line-height: 1.6;
      }
    }
    .item_phone{
      width: 55%;
      background-color: #F6F6F6;
      border-radius: 50px 0 0 0;
      text-align: left;
      img{
        width: 75%;
        margin-left: 30px;
        margin-top:40%;
        transform: translateY(-50%);
      }
    }
  }
  .item2{
    .item_phone{
      border-radius:0 50px 0 0;
    }
    .item_content{
      p{
        margin-left: 15%;
      }
    }
    .item_phone{
      text-align: right;
      img{
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }
}
.content3{
 .content3_main{
    padding-top: 50px;
    width: 100%;
    // height: 100vh;
    background: url('@/assets/images/home/background.png')  center center;
    background-size: 100% 100%;
    color: #515151;
    // display: grid;
    // place-items: center; /* 同时实现水平和垂直居中 */
    .text{
      font-size: 18px;
      width: 50%;
      margin: 0 auto;
      line-height: 1.5;
      .tag{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        span{
          display: flex;
          align-items: center;
        }
        img{
          width: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}

.content4{
  .content4_main{
    width: 70%;
    margin: 0 auto;
    padding-bottom: 50px;
    .grid-content{
      height: 260px;
      box-sizing: border-box;
      margin-bottom: 30px;
      // padding: 50px 0 0 50px;
      border-radius: 20px;
      background: #F6F6F6;
      text-align: left;
      letter-spacing: 1px;
      &:hover{
        border-radius: 20px;
        background: #FFF;
        box-shadow: 2px 10px 20px 0px rgba(149, 149, 149, 0.25);
        .p1{
          color: #DF0628;
        }
        .p2{
          span{
            background-color: #fff;
          }
        }
      }
      p{
        margin-top:30px;
      }
      .p1{
        margin-top: 0;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #38505E;
        font-weight: 600;
        span{
          margin-left: 20px;
        }
        img{
          height: 36px;
        }
      }
      .p2{
        span{
          background: #EEE;
          padding: 8px 11px;
        }
      }
    }
  }
}
.itemUl li{
  cursor: pointer;
}
.itemUl li:hover{
  text-decoration: underline;
}

/* 菜单弹窗 */
::v-deep .el-dialog__header{
  padding: 0 ;
}
::v-deep .el-dialog__body{
  padding: 0 ;
}
</style>
