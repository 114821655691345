<template>
  <div class="container">
    <!-- 药品说明书适⽼化改造 -->
    <div class="content1" ref="content1">
      <p class="title">
        <span style="color:#38505E"> 药品说明书</span> 适老化改造
      </p>
      <p class="sub-title">
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 提倡数字化阅读</span>
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 大字体和清晰的排版</span>
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 音视频播放功能</span>
        <span><img src="@/assets/images/icon/duigou.png" alt=""> 易于导航的结构</span>
      </p>
      <div class="btns">
        <el-button class="red_btn" @click='tolocation'>免费试用</el-button>
        <el-button class="primary_btn" @click="tolocation">合作咨询</el-button>
      </div>
      <p style="text-align:left;width:70%;margin:0 auto;">
        <span v-html="svgAll.menu" @click="menuVisible = true"></span>
      </p>
<!--      <div style="position: relative;">
        <img v-if="!isPlay" @click="playVideo" class="controlls" src="@/assets/images/Solution/Icon.png" alt="">
        <img v-if="isPlay" @click="playVideo" class="controlls" src="@/assets/images/Solution/Icon2.png" alt="">
        <img src="@/assets/images/Solution/videobg.png" class="videobg" alt="">
        <video controls ref="video" style="width: 99.9%">
          <source src="@/assets/images/Solution/video.mp4" type="video/mp4">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>-->
    </div>
    <!-- 药品说明书适⽼化改造 -->
    <div style="width: 100%;background: #fff;padding-top: 50px">
      <div class="content2">
        <div class="title-box">
          <div>
            <p class="sub-title">
              药品说明书
            </p>
            <p class="title">
              <span>适老化改造趋势</span>
            </p>
          </div>
          <p>
            <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px">适老趋势
          </p>
        </div>
        <hr style="border: 1px solid #F6F6F6;margin:50px 0">
        <div class="content2_main">
          <div class="grid-content">
            <p >•  2023年3月起</p>
            <div class="article-box">
              <p class="p1">包括央视在内的国内众多主流媒体重点报导药品说明书</p>
              <hr style="border: 1px solid #D0D0D0;margin:20px 0">
              <img src="@/assets/images/Solution/article1.png" width="80%" >
              <p class="p2">
                “打开药品说明书，一行行如蚂蚁般的小字密密麻麻，让人看的有点头晕。”
              </p>
              <p class="p3">
                这是江苏省连云港市⼈⺠检察院第六检察部主任姜艳玲第⼀次看到药物硝苯地平的说明书时的“初印象” 。当地检察机关通过制发检察建议，推动药监部⻔在该市⼏家药企开展药品说明书“适⽼化”改造试点⼯作。（2023年3⽉29 ⽇《中国⻘年报》）
              </p>
            </div>
          </div>
          <div class="grid-content">
            <p>•  2023年10月</p>
            <div class="article-box" style="padding: 30px;">
              <p class="p1" style="text-align:left;">国家药监局正式发文启动</p>
              <p class="p1" style="text-align:left;">适老化改革试点</p>
              <hr style="border: 1px solid #D0D0D0;margin:20px 0">
              <img src="@/assets/images/Solution/article2.png" width="100%" >
            </div>
          </div>


          <!-- <el-row :gutter="20">
            <el-col :span="12">
            </el-col>
            <el-col :span="12">
            </el-col>
          </el-row> -->
        </div>
      </div>
    </div>

    <hr style="border: 1px solid #F6F6F6;margin:50px 0">
    <!-- 可靠的适老化改造方案 -->
    <div class="content3">
      <div class="title-box">
        <div>
          <p class="sub-title">
            药品说明书
          </p>
          <p class="title">
            <span>可靠的适老化改造方案</span>
          </p>
        </div>
        <p>
          <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px">可靠稳定
        </p>
      </div>
      <hr style="border: 1px solid #F6F6F6;margin:50px 0">
      <div class="content3_main">
        <div class="left">
          <div class="p1">
            让药企数字化更简单
          </div>
          <div class="p2">
            配合国家药监局《药品说明书适老化改革试点工作方案》 通过对药品说明书电子化并实现适老化设计，确保老年用户能够轻松访问、阅读和理解药品说明书，从而提高药品使用的安全性和便利性。
          </div>
          <p style="margin-bottom: 20px;">
            <span @click='tolocation' v-html="svgAll.btn"></span>
          </p>
        </div>
        <div class="right">
          <img src="@/assets/images/Solution/computer.png" alt="">
        </div>
      </div>
      <div class="content3_step">
        <el-row :gutter="30">
          <el-col :span="6">
            <div class="grid-content">
              <div style="align-self: flex-start;">
                <p class="step">01</p>
                <p class="step_name">创建药品</p>
              </div>
              <img style="align-self: flex-end" src="@/assets/images/Solution/icon/medicine.png" alt="">
            </div>
          </el-col >
          <el-col :span="6">
            <div class="grid-content">
              <div style="align-self: flex-start;">
                <p class="step">02</p>
                <p class="step_name">制作说明</p>
              </div>
              <img style="align-self: flex-end" src="@/assets/images/Solution/icon/book.png" alt="">
            </div>
          </el-col >
          <el-col :span="6">
            <div class="grid-content">
              <div style="align-self: flex-start;">
                <p class="step">03</p>
                <p class="step_name">系统核准</p>
              </div>
              <img style="align-self: flex-end" src="@/assets/images/Solution/icon/file.png" alt="">
            </div>
          </el-col >
          <el-col :span="6">
            <div class="grid-content">
              <div style="align-self: flex-start;">
                <p class="step">04</p>
                <p class="step_name">系统核准</p>
              </div>
              <img style="align-self: flex-end" src="@/assets/images/Solution/icon/search.png" alt="">
            </div>
          </el-col >

        </el-row>
      </div>
    </div>
    <div class="advantage">
      <div>
        <span style="color: #38505E;font-size: 22px;font-weight: 600;">药品说明书适老化方案优势&能力 </span>
        <p class="caption">致力于利用最新的技术手段，为老年人提供更安全、更便捷、更易于理解的用药指南，以实现更好的医疗服务和社会效益。</p>
      </div>
      <div class="item">
        <el-row :gutter="30">
          <el-col :span="12">
            <div class="grid-content">
              <img style="width: 100%;" src="@/assets/images/Solution/social.png" alt="">
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p style="text-decoration-line: underline;">社会意义</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <ul>
                <li>提升老年人用药安全性</li>
                <li>改善老年人健康管理质量</li>
                <li>促进医疗服务普惠性</li>
                <li>环保行动</li>
            </ul>
            </div>
          </el-col>
        </el-row>
        <hr style="border: 1px solid #CCC;margin:50px 0">
      </div>
      <div class="item">
        <el-row :gutter="30">
          <el-col :span="12">
            <div class="grid-content">
              <img style="width: 30%;" src="@/assets/images/Solution/about.png" alt="">
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p style="text-decoration-line: underline;">对于药业</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <ul>
                <li>成本效益和资源节约</li>
                <li>市场竞争力提升</li>
                <li>客户满意度提升</li>
                <li>增强企业社会责任形象</li>
            </ul>
            </div>
          </el-col>
        </el-row>
        <hr style="border: 1px solid #CCC;margin:50px 0">
      </div>
      <div class="item">
        <el-row :gutter="30">
          <el-col :span="12">
            <div class="grid-content">
              <img style="width: 30%;" src="@/assets/images/Solution/provider.png" alt="">
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <p style="text-decoration-line: underline;">服务商</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <ul>
                <li>技术创新与智能化</li>
                <li>用户体验优化</li>
                <li>持续改进与迭代</li>
                <li>法规合规保障</li>
                <li>行业积淀</li>
            </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 适老化改造案例 -->
    <div class="content4">
      <div class="title-box">
        <div>
          <p class="sub-title">
            药品说明书
          </p>
          <p class="title">
            <span>适老化改造案例</span>
          </p>
        </div>
        <p>
          <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px">苏州二叶
        </p>
      </div>
      <hr style="border: 1px solid #F6F6F6;margin:50px 0">
      <img width="100%" src="@/assets/images/Solution/Dashboard.png" alt="">

      <div class="img_box">
        <div class="grid-content" style="width: 60%;margin-right:50px">
          <p class="p_title">
            使用手机扫描药盒的电子说明书二维码， <br> 即可查看药品说明书。
          </p>
          <div class="item">
            <p>用户手机扫码后显示药品说明书，包括药企品牌、药品介绍等。</p>
          </div>
          <div class="item">
            <p>⻚⾯简洁明了，字体加⼤加粗，提升老人阅读体验。</p>
          </div>
          <div class="item">
            <p>丰富的查看方式：文字、语音、视频等，方便老人选用。</p>
          </div>
        </div>
        <div class="grid-content">
          <img width="100%" src="@/assets/images/Solution/erye.png" alt="">
        </div>
      </div>
    </div>
    <!-- 电子药品说明书系统 -->
    <div class="content5">
      <div class="explanation">
        <img src="@/assets/images/Solution/red_img.png" alt="">
      </div>
      <div class="content5_main">
        <hr style="border: 1px solid #ccc;margin:50px 0">
        <div class="item">
          <div class="left">
            10000+ <span style="font-size: 34px;">份</span>
          </div>
          <div class="right">
            <p>成功发行上万份电子说明书</p>
            <p>稳定持续可用，即扫即看</p>
            <p>发行流程合法合规，助力药企数字化升级</p>
          </div>
        </div>
        <hr style="border: 1px solid #ccc;margin:50px 0">
        <div class="item">
          <div class="left">
            20+ <span style="font-size: 34px;">年</span>
          </div>
          <div class="right">
            <p>系统稳定运行20余年</p>
            <p>搭载国内知名云平台服务器，稳定可靠有保障</p>
            <p>通过不断的技术升级与优化，确保系统运行无障碍</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 菜单 -->
    <el-dialog
      title=""
      :visible.sync="menuVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="70%"
      >
      <div class="menu">
        <div class="left">
          <div class="left-top">
            <div class="logo">
              <img src="@/assets/images/logo/uoon_logo.png" width="40px">
              <div >
                <p style="font-size: 18px;letter-spacing:2px">双云科技</p>
                <p style="font-size: 12px;">助力数字化成功</p>
              </div>
            </div>
            <div style="font-size: 22px;font-weight: 600;line-height: 1.5">
              <p>提升您的资料管理效率，<br />驱动业务数字化。</p>
            </div>
            <div class="address">
              <p>公司地址：</p>
              <hr style="border:1px solid #ECECEC;margin:10px 0;">
              <p>苏州双云信息科技有限公司</p>
              <p>江苏省苏州市相城区嘉元路959号元和大厦</p>
            </div>
          </div>

          <div class="bottom">
            <p style="margin-bottom: 20px;"> <span style="font-size: 20px;color:#fff;">联系我们 </span>
            咨询或合作</p>

            <p>深耕二十余年，积累了丰富的行业经验和专业知识。无论您是寻求提升业务效率的新方法，还是需要解决复杂的行业挑战，我们都将为您提供全面的支持和专业的指导。</p>
          </div>

        </div>

        <div class="center">
          <p>双云科技</p>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>
              <img src="@/assets/images/Solution/triangle.png" alt="" style="margin-right:8px;width:12px;">解决方案
            </p>
            <ul class="itemUl">
              <li @click="toPages('a')">药品说明书适老化改造</li>
              <li>装备制造业解决方案</li>
              <li @click="toPages('c')">电子说明书解决方案</li>
              <li>建筑行业解决方案</li>
            </ul>
          </div>
          <hr style="border:1px solid #ECECEC; margin:30px 0;">
          <div class="item">
            <p>合作与支持</p>
            <ul class="itemUl">
              <li @click="toPages2('a')">公司简介</li>
              <li @click="toPages2('b')">联系我们</li>
              <li @click="toPages2('c')">合作客户</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <span >关闭</span>
          <img src="@/assets/images/icon/close.png" @click="closeMenu">
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import svgAll from '@/assets/images/svg/svg.js';
  export default {
    data(){
      return{
        svgAll,
        player: null,
        menuVisible:false,
        isPlay: false, //是否在播放 默认false否
      }
    },
    mounted(){
      // this.$nextTick(() => {
      //   this.player = new Player({
      //     id: 'video',
      //     height: window.innerHeight,
      //     width: window.innerWidth,
      //     url: '../../assets/images/Solution/video.mp4'
      //   });
      // })
      this.$refs.video.addEventListener(
          'ended',
          () => {
            //结束
            this.isPlay = false;
            this.$refs.video.pause();
          },
          false
      );
    },
    methods:{
      closeMenu(){
        this.menuVisible = false
      },
      toYun() {
        window.open('https://yun.uoon.cn/registrationPage', '_blank');
      },
      toTop(type) {
        this.$nextTick(() => {
          if (this.$refs.content1) {
           if (type == 'a') {
             window.scrollTo({
               top: 0,
               behavior: 'smooth',
             });
           }
          }
        });
      },
      playVideo() {
        this.isPlay = !this.isPlay;
        if(!this.isPlay) {
          this.$refs.video.pause();
        } else {
          this.$refs.video.play();
        }

      },
      tolocation() {
        this.$emit('tolocation' ,'');
      },
      toPages(type) {
        this.menuVisible = false;
        this.$emit('toPages', type);
      },
      toPages2(type) {
        this.menuVisible = false;
        this.$emit('toPages2', type);
      },
    }
  }
</script>

<style lang="scss" scoped>
.container{
  margin: 0 auto;
  text-align: center;
  width: 100%;
  .title{
    color: #DF0628;
    font-size: 46px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 4px;
  }
  .sub-title{
    font-size: 34px;
    line-height: 1.2;
    color: #38505E;
    font-weight: 600;
  }
}
.content1{
  margin-bottom: 60px;
  .sub-title{
    color: #38505E;
    font-size: 14px;
    line-height: 2;
    font-weight: normal;
    span{
      margin: 10px;
    }
  }
  .btns{
    margin:30px
  }
}

.content2{
  width: 70%;
  margin: 0 auto;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items:end;
    text-align: left;
  }
  .content2_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .grid-content{
      width: 46%;
    }
    .article-box{
      margin-top: 20px;
      padding: 40px;
      border-radius: 20px;
      background: #F6F6F6;
      box-shadow: 0px 2px 4px 0px rgba(146, 146, 146, 0.25);
      text-align:center;
      .p1,.p2{
        color: #38505E;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 2PX;
      }
      .p2{
        text-align: left;
        font-size: 18px;
        margin: 20px 0;
      }
      .p3{
        text-align: left;
        font-size: 14px;
        color: #555;
        line-height: 1.5;
      }
    }
  }
}

.content3{
  width: 70%;
  margin: 0 auto;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items:end;
    text-align: left;
  }
  .content3_main{
    display: flex;
    justify-content: space-between;
    .left{
      width: 40%;
      line-height: 1.5;
      letter-spacing: 2px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .p1{
        color: #38505E;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
      }
      .p2{
        color: #333;
        font-size: 14px;
        margin: 20px 0 30px 0;
      }
    }
    .right{
      flex: 1;
      text-align: right;
      img{
        width: 80%;
      }
    }
  }
  .content3_step{
    margin: 50px 0;
    .grid-content{
      height: 150px;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #F1F1F1;
      background: #F7F7F7;
      display: flex;
      justify-content: space-between;
      text-align: left;
      .step{
        color: #38505E;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.8;
      }
      .step_name{
        color: #38505E;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
      }
      img{
        width: 50px;
      }
    }
  }
}
.advantage{
  width: 70%;
  padding: 60px 15%;
  background: #F6F6F6;
  text-align: left;
  .caption{
    width: 330px;
    color: #333;
    font-size: 12px;
    line-height: 156.5%;
    margin: 15px 0;
  }
  .item{
    .grid-content{
      ul{
        li{
          color: #333;
          line-height: 2;
        }
      }
    }
  }
}
.content4{
  width: 70%;
  margin: 50px auto;
  text-align: left;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items:end;
  }
  .img_box{
    display: flex;
    align-items: center;
    .p_title{
      text-align: left;
      margin-bottom: 30px;
      color: #38505E;
      font-size: 18px;
      line-height: 160%;
    }
    .item{
      height: 80px;
      margin: 20px 0;
      background: url('@/assets/images/Solution/Item_bg.png') ;
      background-size: 100% 100%;
      color: #313131;
      font-weight: 400;
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
  }
}
.content5{
  background: url('@/assets/images/home/background.png') no-repeat ;
  background-size: 100% 100%;
  padding: 80px 0;
  padding-bottom: 160px;
  .explanation{
    img{
      border-radius: 50px;
      width: 85%;
    }
  }
  .content5_main{
    width: 70%;
    margin: 0 auto;
    .item{
      display: flex;
      text-align: left;
      align-items: center;
      .left{
        width: 60%;
        color: #313131;
        font-size: 90px;
        font-weight: 500;
      }
      .right{
        color: #333;
        font-size: 16px;
        line-height: 3;
      }
    }
  }
}
.videobg {
  position: absolute;
  left: 0;
  background-image: url("../../assets/images/Solution/videobg.png");
  background-size: 100%;
  z-index: 600;
  width: 100%;
}
//所有控件
video::-webkit-media-controls-enclosure{
  display: none;
}
.controlls {
  position: absolute;
  bottom: 100px;
  right: 50px;
  z-index: 650;
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.itemUl li{
  cursor: pointer;
}
.itemUl li:hover{
  text-decoration: underline;
}
/* 菜单弹窗 */
::v-deep .el-dialog__header{
  padding: 0 ;
}
::v-deep .el-dialog__body{
  padding: 0 ;
}
</style>
